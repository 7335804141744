document.addEventListener('DOMContentLoaded', function () {
	let configString = window.localStorage.getItem('betBotConfig');
	let destroyBetBot = true;

	if (configString) {
		let config = JSON.parse(configString);
		if (config && config.showBetBot) {
			destroyBetBot = false;
		}
	}

	if (destroyBetBot) {
		document.getElementById('bet-bot-plugin').remove();
	} else {	
		importJavascriptFile('https://betbot.staging.bets4us.com/js/bet-bot-plugin.js#webApiUrl=https%3A%2F%2Fgp-webapi.staging.bets4us.com&bets4usApiUrl=https%3A%2F%2Fapi.staging.bets4us.com&webApiAuthKey=7x!z%25C*F-JaNdRgUkXp2s5v8y%2FB%3FD(G%2BKbPeShVmYq3t6w9z%24C%26F)H%40McQfTjWnZr4u7x!A%25D*G-KaNdRgUkXp2s5v8y%2FB%3FE(H%2BMbQeShVmYq3t6w9z%24C%26F)J%40NcRfUjWnZr4u7x!A%25D*G-KaPdSgVkYp3s5v8y%2FB%3FE(H%2BMbQeThWmZq4t7w9z%24C%26F)J%40NcRfUjXn2r5u8x%2FA%25D*G-KaPdSgVkYp3s6v9y%24B%26E(H%2BMbQeThWmZq4t7w!z%25C*F-J%40&productKey=RfUjXn2r5u8x!A%25D*G-KaPdSgVkYp3s6v9y%24B%3FE(H%2BMbQeThWmZq4t7w!z%25C*F)J%40NcRfUjXn2r5u8x%2FA%3FD(G%2BKaPdSgVkYp3s6v9y%24B%26E)H%40McQeThWmZq4t7w!z%25C*F-JaNdRgUjXn2r5u8x%2FA%3FD(G%2BKbPeShVmYp3s6v9y%24B%26E)H%40McQfTjWnZr4t7w!z%25C*F-JaNdRgUkXp2s5v8x%2FA%3FD(G%2BKbPeShVmYq3t6w9z%24B%26E)H%40McQfTjWnZr4u7');
		importCssFile('https://betbot.staging.bets4us.com/css/app.css');
	}
}, false);

function importJavascriptFile(src){
	var scriptElem = document.createElement('script');
	scriptElem.setAttribute('src', src);
	scriptElem.setAttribute('type', 'text/javascript');
	document.getElementsByTagName('body')[0].appendChild(scriptElem);
}

function importCssFile(src){
	var linkElem = document.createElement('link');
	linkElem.setAttribute('href', src);
	linkElem.setAttribute('type', 'text/css');
	linkElem.setAttribute('rel', 'stylesheet');
	document.getElementsByTagName('head')[0].appendChild(linkElem);
}